import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { LanguageProvider } from './context/LanguageContext'
import Homepage from './Homepage/Homepage'
import Map from './Map'
import Calculator from './Calculator/Calculator'
import GuideOverview from './Pages/Guide/GuideOverview'
import DetailPage from './Pages/DetailPage'

function App () {
  return (
    <LanguageProvider>
      <Router>
        <div className='App'>
          {/* Define the different routes */}
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/map" element={<Map />} />
            <Route path="/investcalculator" element={<Calculator />} />
            <Route path="/guide" element={<GuideOverview />} />
            <Route path="/guide/:articleUrl" element={<DetailPage />} />
            <Route path="/:PageId" element={<DetailPage />} />
            {/* You can add more routes here for other pages */}
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  )
}

export default App
