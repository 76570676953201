import React, { useState, useRef, useEffect } from 'react';
import LegendElement from './LegendElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import colors from '../../utils/colors';
import config from '../../utils/config';
import generateFilterOptions from '../../utils/filters/generateFilterOptions';
import { translate } from '../../utils/translationManager';
import { useLanguage } from '../../context/LanguageContext';
import './Legend.css'; // Import your CSS file

const Legend = ({ layerLegend, isLayerVisible, handleApplyFilters, activeFilters, mainFilter }) => {
  const [isScrollable, setIsScrollable] = useState(false); // Tracks if the container is scrollable
  const scrollContainerRef = useRef(null);
  const { language } = useLanguage();
  const filterOptions = generateFilterOptions(config.filters.buildingTypesCategory);

  // Function to check if the container is scrollable
  const checkScrollable = () => {
    if (scrollContainerRef.current) {
      setIsScrollable(scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth);
    }
  };

  // Check for scrollable content on component mount and when layerLegend changes
  useEffect(() => {
    checkScrollable();
  }, [layerLegend]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  const isFilterApplied = (activeFilters, filterKey, filterValue) => {
    // Ensure activeFilters and filterRange are defined
    if (!activeFilters || !activeFilters.filterRange) {
      return true;
    }
  
    // Find the filter object with the given filterKey
    const filter = activeFilters.filterRange.find(f => f.key === filterKey);

    // check if filterkey overall exists, if not, set true (no Filters have been deployed)
    if (!filter) {
      return true;
    }
    // Check if the filter exists and if the value array includes the filterValue
    if (filter && filter.value.includes(filterValue)) {
      return true;  // Return true if both key and value exist
    }
  
    return false;  // Return false if the key or value doesn't exist
  };

  return (
    <>
      {isLayerVisible && (
        <div style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bottom: '3%',
          height: 50,
          width: 'calc(100% - 20px)',
          padding: '0 10px',
        }}>
          {/* Left Scroll Button - Only visible if the content is scrollable */}
          {isScrollable && (
            <button
              onClick={scrollLeft}
              style={{
                backgroundColor: colors.buttons.primary,
                padding: '20px 10px',
                border: 'none',
                borderRadius: 10,
                cursor: 'pointer',
                marginRight: 5,
                fontSize: 16,
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)'
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}

          {/* Scrollable Container */}
          <div
            className="filterScrollContainer"
            ref={scrollContainerRef}
            style={{
              flexDirection: 'row',
              display: 'flex',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              maxWidth: 800,
              height: 60,
             //padding: '0 10px',
              backgroundColor: colors.buttons.primary,
              borderRadius: 10,
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
              whiteSpace: 'nowrap',  // Ensures the elements stay on one line
              alignItems: 'center',
              borderLeft: `solid 10px ${colors.buttons.primary}`,
              borderRight: `solid 10px ${colors.buttons.primary}`
            }}
          >
            {layerLegend.map((layer, index) => (
              <LegendElement
                key={index}
                filterKey={mainFilter}
                applyFilter={handleApplyFilters}
                label={translate(layer.key,language)}
                value={layer.key}
                options={filterOptions}
                color={layer.color}
                activeFilters={activeFilters}
                isFilter={isFilterApplied(activeFilters, mainFilter, layer.key)}
              />
            ))}
          </div>

          {/* Right Scroll Button - Only visible if the content is scrollable */}
          {isScrollable && (
            <button
              onClick={scrollRight}
              style={{
                backgroundColor: colors.buttons.primary,
                padding: '20px 10px',
                border: 'none',
                borderRadius: 10,
                cursor: 'pointer',
                marginLeft: 5,
                fontSize: 16,
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Legend;
